import Api from '@/axios'

export default {
  getAllWrongEntry (pageNumber) {
    return Api().get(`memberships/wrong-entries?page=${pageNumber}`)
  },
  requestWrongEntry (reason) {
    return Api().post('memberships/wrong-entries/store', reason)
  },
  changeWrongEntryStatus (type, payload) {
    return Api().post(`memberships/wrong-entries/status/${type}`, payload)
  }
}
