<template>
  <b-container fluid>
    <b-modal id="wrongEntryData" size="lg"  :title="'History'" hide-footer no-close-on-backdrop>
      <wrong-entry :wrongEntryData="wrongEntryDataSteps" @sendWrongEntry="sendWrongEntry"/>
    </b-modal>
    <!--Start Content Page -->
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Wrong Entry</h4>
          </template>
          <template v-slot:body>
            <div v-if="loadTable" class="text-center">
              <spinner-loading text = 'Loading' />
            </div>
            <b-table v-else striped responsive  :fields="columns" :items="wrongEntryList" class="mb-0 table-borderless" ref="selectableTable" sort-icon-left primary-key="phone"  id="table-transition-example" :tbody-transition-props="transProps">
              <template v-slot:cell(status)="data">
                <i :class="['las la-check-circle font-size-22 ml-2',
                data.item.first_status=='pending'?'text-gray' : data.item.first_status=='approved'?'text-success':
                'text-danger']"></i>
                <i  :class="['las la-check-circle font-size-22 ml-2',data.item.second_status=='pending'? 'text-gray' :
                 data.item.second_status=='approved'?'text-success':
                'text-danger']"></i>
                <i  :class="['las la-check-circle font-size-22 ml-2',data.item.third_status=='pending'? 'text-gray' :
                 data.item.third_status=='approved'?'text-success':
                'text-danger']"></i>
              </template>
              <template v-slot:cell(action)="data">
                <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="showWrongEntry(data.item)">
                  <i class="las la-eye"></i>
                </b-button>
              </template>
            </b-table>
            <b-pagination v-model="pagination.current_page"
                          class="mt-3"
                          :total-rows="pagination.total"
                          align="right"
                          :per-page="pagination.per_page"
                          aria-controls="my-table"
                          @input="getAllWrongEntry"
            >

            </b-pagination>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!--End Content Page -->
  </b-container>
</template>
<script>
import wrongEntryService from '../services/rongEntry'
import wrongEntry from '../components/wrongEntry'
import { core } from '@/config/pluginInit'
export default {
  data () {
    return {
      wrongEntryDataSteps: null,
      loadTable: false,
      transProps: {
        name: 'flip-list'
      },
      pagination: {
        current_page: 1,
        per_page: 10,
        total: 6
      },
      wrongEntryList: [],
      columns: [{ label: 'User Name', key: 'user.name', class: 'text-left' },
        { label: 'Date', key: 'created_at', class: 'text-left' },
        { label: 'Member Name ', key: 'member.name', class: 'text-left' },
        { label: 'Package Name ', key: 'data[0].title', class: 'text-left' },
        { label: 'Cost', key: 'data[0].cost', class: 'text-left' },
        { label: 'Net', key: 'data[0].net', class: 'text-left' },
        { label: 'Paid', key: 'data[0].paid', class: 'text-left' },
        { label: 'Reason', key: 'reason', class: 'text-left' },
        { label: 'Attachment', key: 'media_id', class: 'text-left' },
        { label: 'Status', key: 'status', class: 'text-left' },
        { label: 'Action', key: 'action', class: 'text-left' }
      ]
    }
  },
  components: {
    wrongEntry
  },
  methods: {
    getAllWrongEntry () {
      wrongEntryService.getAllWrongEntry(this.pagination.currentPage).then(res => {
        this.wrongEntryList = res.data.data
        this.pagination.current_page = res.data.data.meta.current_page
        this.pagination.per_page = res.data.data.meta.per_page
        this.pagination.total = res.data.data.meta.total
      })
    },
    showWrongEntry (data) {
      this.wrongEntryDataSteps = data
      this.$bvModal.show('wrongEntryData')
    },
    sendWrongEntry (payload) {
      wrongEntryService.changeWrongEntryStatus(payload.type, payload).then(() => {
        this.$bvModal.hide('wrongEntryData')
        this.getAllWrongEntry()
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.getAllWrongEntry()
  }
}
</script>
